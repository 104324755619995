import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import axios from 'axios';
import { Form, Input, Button, Upload, message, ConfigProvider } from 'antd';
import useToggle from '../../Hooks/useToggle';
import WorkshopAnnouncement from './WorkshopAnnouncement';
import BackToTop from '../BackToTop';
import FooterHomeOne from '../HomeOne/FooterHomeOne';
import Drawer from '../Mobile/Drawer';
import HeaderNews from '../News/HeaderNews';
import './class.css';

// Define Zod schema for form validation
const schema = z.object({
  firstName: z.string().min(1, 'نام و نام خانوادگی الزامی است'),
  email: z.string().email('فرمت ایمیل اشتباه است'),
  phone: z.string().optional(),
  receipt: z
    .instanceof(File)
    .refine(
      (file) => ['image/jpeg', 'image/png'].includes(file.type),
      'فقط فایل های فرمت png , jpeg را آپلود کنید'
    ),
  paymentDescription: z.string().optional(),
});

const apiUrl = process.env.REACT_APP_API_URL;

const WebinarForm = () => {
  const [drawer, drawerAction] = useToggle(false);

  const {
    control,
    handleSubmit,
    // setValue,
    formState: { errors },
    reset,
  } = useForm({
    resolver: zodResolver(schema),
  });

  const [fileList, setFileList] = useState([]); // Change Flag: State to manage uploaded files
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('name', data.firstName);
      formData.append('email', data.email);
      formData.append('contact_number', data.phone);
      formData.append('attachment', data.receipt); // Directly use the file instance
      formData.append('description', data.paymentDescription || '');

      // Post form data to the endpoint
      await axios.post(`${apiUrl}/api/support/webinar`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      reset(); // Reset the form after successful submission
      setFileList([]); // Clear the file list after submission
      message.success({
        content:
          'ثبت نام شما با موفقیت انجام شد. در صورت تایید پرداخت، لینک و اطلاعات شرکت در کارگاه روز پنج شنبه به ایمیل شما ارسال خواهد شد',
        duration: 8, // Set duration to 8 seconds
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      message.error('Failed to submit the form');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Drawer drawer={drawer} action={drawerAction.toggle} />
      <HeaderNews action={drawerAction.toggle} />
      <div className="appie-shop-grid-area pt-100 pb-50">
        <div className="container">
          <div className="">
            <WorkshopAnnouncement />

            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: '#358398',
                },
              }}
            >
              <Form
                layout="vertical"
                onFinish={handleSubmit(onSubmit)}
                style={{ maxWidth: '400px', margin: 'auto' }}
                labelAlign="right" // Align all labels to the right
              >
                {/* First Name */}
                <Form.Item
                  label="نام و نام خانوادگی"
                  validateStatus={errors.firstName ? 'error' : ''}
                  help={errors.firstName?.message}
                  labelAlign="right"
                  style={{ flexDirection: 'row-reverse' }}
                  className="antdinput"
                >
                  <Controller
                    name="firstName"
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        status={errors.firstName ? 'error' : ''}
                        placeholder="Enter your first name"
                      />
                    )}
                  />
                </Form.Item>

                {/* Email */}
                <Form.Item
                  label="ایمیل"
                  validateStatus={errors.email ? 'error' : ''}
                  help={errors.email?.message}
                  className="antdinput"
                >
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="email"
                        status={errors.email ? 'error' : ''}
                        placeholder="Enter your email"
                      />
                    )}
                  />
                </Form.Item>

                {/* Phone Number */}
                <Form.Item
                  label="شماره تلفن همراه"
                  validateStatus={errors.phone ? 'error' : ''}
                  help={errors.phone?.message}
                  className="antdinput"
                >
                  <Controller
                    name="phone"
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="tel"
                        status={errors.phone ? 'error' : ''}
                        placeholder="Enter your phone number"
                      />
                    )}
                  />
                </Form.Item>

                {/* Upload Receipt */}
                <Form.Item
                  label="آپلود رسید پرداخت"
                  validateStatus={errors.receipt ? 'error' : ''}
                  help={errors.receipt?.message}
                  className="antdinput"
                >
                  <Controller
                    name="receipt"
                    control={control}
                    render={({ field: { onChange } }) => (
                      // Change Flag: Modify Upload to manage file list
                      <Upload
                        accept=".jpeg,.png"
                        beforeUpload={(file) => {
                          onChange(file); // Set file in form state
                          setFileList([file]); // Change Flag: Set the uploaded file in the state
                          return false; // Prevent automatic upload
                        }}
                        fileList={fileList} // Change Flag: Display uploaded files
                        showUploadList // Show the uploaded file list
                      >
                        <Button
                          color="default"
                          variant="outlined"
                          type="dashed"
                          className="w-100"
                        >
                          برای آپلود رسید, کلیک کنید
                        </Button>
                      </Upload>
                    )}
                  />
                </Form.Item>

                {/* Payment Description */}
                <Form.Item
                  className="antdinput"
                  label="توضیحات پرداخت"
                  validateStatus={errors.paymentDescription ? 'error' : ''}
                  help={errors.paymentDescription?.message}
                >
                  <Controller
                    name="paymentDescription"
                    control={control}
                    render={({ field }) => (
                      <Input.TextArea
                        {...field}
                        rows={4}
                        status={errors.paymentDescription ? 'error' : ''}
                        placeholder="Describe the payment"
                      />
                    )}
                  />
                </Form.Item>

                {/* Submit Button */}
                <Form.Item className="antdinput">
                  <Button
                    loading={loading}
                    className="w-100"
                    type="primary"
                    htmlType="submit"
                  >
                    ثبت نام
                  </Button>
                </Form.Item>
              </Form>
            </ConfigProvider>
          </div>
        </div>
      </div>
      <FooterHomeOne />
      <BackToTop />
    </>
  );
};

export default WebinarForm;
