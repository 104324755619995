import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AboutUs from './components/AboutUs';
import AboutUsTwo from './components/AboutUs/AboutUsTwo';
import Contact from './components/Contact';
import Error from './components/Error';
import Loader from './components/Helper/Loader';
import ScrollToTop from './components/Helper/ScrollToTop';
import HomeDark from './components/HomeDark';
import HomeOne from './components/HomeOne';
import HomeRtl from './components/HomeRtl';
import News from './components/News';
import SingleNews from './components/News/SingleNews';
import Positions from './components/PositionsService';
import Supervisors from './components/SupervisorsService';
import Shops from './components/Shops';
import Webinar from './components/Webinar';
import ShopDetails from './components/Shops/Details';

function Routes() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  });
  return (
    <>
      {loading && (
        <div className={`appie-loader ${loading ? 'active' : ''}`}>
          <Loader />
        </div>
      )}
      <div className={`appie-visible ${loading === false ? 'active' : ''}`}>
        <Router>
          <ScrollToTop>
            <Switch>
              <Route exact path="/" component={HomeOne} />
              <Route exact path="/home-dark" component={HomeDark} />
              <Route exact path="/home-rtl" component={HomeRtl} />
              <Route exact path="/news" component={News} />
              <Route exact path="/news/single-news" component={SingleNews} />
              <Route exact path="/positions" component={Positions} />
              <Route exact path="/supervisors" component={Supervisors} />
              <Route exact path="/about-us" component={AboutUs} />
              <Route exact path="/contact" component={Contact} />
              <Route exact path="/error" component={Error} />
              <Route exact path="/about-us-another" component={AboutUsTwo} />
              <Route exact path="/shops" component={Shops} />
              <Route exact path="/webinar" component={Webinar} />
              <Route exact path="/shops/shop-details" component={ShopDetails} />
              <Route component={Error} />
            </Switch>
          </ScrollToTop>
        </Router>
      </div>
    </>
  );
}

export default Routes;
