import React from 'react';
import { Typography, Tag, List } from 'antd';
import wb from '../../assets/images/webinar.png';

const { Title, Paragraph, Text } = Typography;

const WorkshopAnnouncement = () => (
  <div
    dir="rtl"
    style={{
      padding: '20px',
      backgroundColor: '#f9f9f9',
      borderRadius: '8px',
      textAlign: 'right',
      marginBottom: '22px',
    }}
  >
    {/* Load Image */}
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <img
        src={wb} // Change to your actual image filename
        alt="Workshop Announcement"
        style={{
          width: '100%',
          maxWidth: '400px',
          marginBottom: '22px',
          borderRadius: '8px',
        }} // Style as needed
      />
    </div>
    <Title level={4} style={{ color: '#358398' }}>
      <span role="img" aria-label="bookmark">
        📚
      </span>
      <span role="img" aria-label="bookmark">
        📚
      </span>
      یه خبر خوب
      <span role="img" aria-label="tada">
        🎉
      </span>
    </Title>
    <Paragraph>
      بالاخره بعد از کلی درخواست، تصمیم گرفتیم که کارگاه اپلای و مهاجرت تحصیلی
      با بورسیه برگزار کنیم و جواب خیلی از سوالاتتون رو بدیم. این کارگاه براساس
      تجربه و دیدگاه شخصی یک فرد نیست و توسط تیم کایت از مجموعه تجربه ها،
      سوالات، چالش ها و البته اشتباهات مکرر آدم های زیادی که تو پنج سال اخیر، تو
      کانال بودن و درنهایت اپلای موفق یا غیرموفق داشتن تشکیل شده
    </Paragraph>

    <Title level={5}>این کارگاه بدرد چه کسایی میخوره:</Title>
    <List
      dataSource={[
        'اونایی که هیچ دیدی نسبت به اپلای تحصیلی با بورسیه ندارن، نمیدونن رزومشون شانسی داره یا نه و از پس مدارک، هزینه ها و پروسه اش برمیان یا نه',
        'اونایی که فکر میکنن برای اپلای مخصوصا امریکای شمالی دیگه دیر شده یا اطلاعاتشون فقط مختص چند کشور محدود هست و از همه فرصت ها باخبر نیستن',
        'اونایی که به همه جای پروسه اپلای، اشراف کامل دارن ولی هنوز توی گرفتن جواب مثبت از استاد، پیدا کردن پوزیشن یا مصاحبه گیر کردن و به نتیجه ای نرسیدن',
      ]}
      renderItem={(item) => (
        <List.Item style={{ textAlign: 'right' }}>
          <Tag color="green" style={{ marginRight: '10px' }}>
            ✔️
          </Tag>
          <p style={{ width: '100%', fontSize: 14 }}>{item}</p>
        </List.Item>
      )}
    />

    <Title level={5}>سرفصل های کارگاه:</Title>
    <List
      dataSource={[
        'مقایسه اپلای آمریکای شمالی (امریکا و کانادا) با کشورهای مطرح اروپایی از لحاظ برنامه زمانی، هزینه، چالش‌های ویزا، شرایط تحصیل و اقامت بعد از تحصیل.',
        'راهنمای جامع هزینه‌ها در اپلای اروپا و آمریکای شمالی، شامل اپلیکیشن فی، آزمون‌ زبان، تایید مدارک، ویزا و موارد جانبی، تمکن مالی، و... به همراه نکاتی برای کاهش هزینه‌های پنهان.',
        'حداقل نیازمندی های یک رزومه برای گرفتن فاند (بورسیه) به همراه نکته های نحوه تقویت رزومه (نحوه استفاده از پروفایل‌های انلاین مثل لینکدین و ریسرچ‌گیت، مهارت ها و حوزه‌های پژوهشی ترند و ...)',
        'بررسی کامل منابع فاند و اسکالرشیپ ها (معرفی نحوه فاندینگ امریکای شمالی و برخی از معروف‌ترین‌ ها در اروپا شامل ماری کوری، چوونینگ، داد، اراسموس و اسکالرشیپ های دانشگاه ها). تفاوت اپلای کمیته محور، پوزیشن محور و استاد محور و استراتژی های لازم برای هرکدوم',
        'چک‌لیست مدارک لازم به همراه نکات مهم و اشتباهات رایج در نوشتن رزومه، انگیزه‌نامه، توصیه‌نامه و پروپوزال تحقیقاتی. همچنین نحوه انتخاب آزمون مناسب زبان (تافل یا آیلتس)',
        'اشتباهات رایج و نکته های افزایش شانس جستجوی پوزیشن (فرصت) فول فاند (بورسیه کامل) و استاد گرنت دار (دارای بودجه تحقیقاتی) مرتبط با حوزه‌ی شما و نحوه استفاده بهینه از وبسایت کایت.',
        'نحوه مدیریت و مهارت‌های نرم ضروری برای تحت تأثیر قرار دادن استاد و کمیته‌ در مصاحبه‌ها.',
        'پرسش و پاسخ',
      ]}
      renderItem={(item) => (
        <List.Item style={{ textAlign: 'right' }}>
          <Text>- {item}</Text>
        </List.Item>
      )}
    />

    <Paragraph style={{ textAlign: 'right' }}>
      گرد اوردنده مطالب و برگزار کننده: <Text strong>تیم اپلای کایت</Text>
    </Paragraph>

    <Title level={5} style={{ color: '#358398' }}>
      بسته حمایتی:
    </Title>
    <Paragraph style={{ textAlign: 'right' }}>
      <Text>
        10 درصد تخفیف خرید اکانت کایت پرو و فیلم های نحوه اپلای بهینه با وبسایت
        کایت، دانلود ویدیوی کارگاه به همراه نمونه فایل های مدارک از وبسایت تا 72
        ساعت، واژه نامه نحوه پر کردن فرم اپلیکیشن ها و اسکالرشیپ ها
      </Text>
    </Paragraph>

    <Paragraph style={{ textAlign: 'right' }}>
      <Text strong>تاریخ برگزاری :</Text> جمعه 20 مهرماه ساعت 10:30 صبح بوقت
      ایران به مدت چهار ساعت و با ظرفیت محدود.
    </Paragraph>
    <Paragraph style={{ textAlign: 'right' }}>
      <Text strong>هزینه:</Text> 800 هزار تومان
    </Paragraph>
    <Paragraph style={{ textAlign: 'right' }}>
      <Text strong>نحوه پرداخت:</Text> برای ثبت نام تا اخر روز پنج شنبه 19 مهر
      ماه، مبلغ ۸۰۰ هزار تومان به شماره کارت
      6037997512902843 به نام حسین حمید واریز کنید. بعد از واریز لازم هست نام و
      اطلاعات تماستون رو به همراه عکس فیش واریزی از طریق فرم زیر برای ما ارسال
      کنید. روز پنج شنبه، لینک و اطلاعات شرکت در کارگاه آنلاین براتون ایمیل
      میشه.
    </Paragraph>
    <Paragraph style={{ textAlign: 'right' }}>
      میتونید سوالات احتمالی رو بطور خصوصی از ایدی تلگرام{' '}
      <Text strong>@applykite</Text> بپرسید.
    </Paragraph>
  </div>
);

export default WorkshopAnnouncement;
